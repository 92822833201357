import Footer from "uis/component/footer";
import { BoxContainer, Container, ContentContainer, FormContainer, Header, RootContainer } from "./index.styles";
import UI from "@mile-meeting/mile-ui";
import { useState } from "react";
import { isValidEmail } from "pages/contact-sales";
import axios from "axios";

const Earlybird = () => {
  const [name, setName] = useState<string>();
  const [company, setCompany] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [meetingRoomCount, setMeetingRoomCount] = useState<number>();
  const [question, setQuestion] = useState<string>();
  const [agree, setAgree] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const canOnHandleSubmit = () => {
    if (email && !isValidEmail(email)) {
      return false;
    }
    return name && company && email && meetingRoomCount && agree;
  };

  const sendSlackMessage = async () => {
    const format = `
    *🚀 [Mile Meeting 자체예약 얼리버드 혜택 도입 신청]*

        이름: ${name}
        회사명: ${company}
        이메일: ${email}
        회의실 수: ${meetingRoomCount}
        문의사항: ${question}
    `;

    const res = await fetch("https://renew-slack-dev.mile.im/business/notification", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        format,
        channelId: "C07PJQ44P7T",
      }),
    });
  };

  const submit = async () => {
    if (!canOnHandleSubmit()) return;
    setLoading(true);
    if (loading) return;
    else {
      sendSlackMessage();
      const res = await axios.post("https://be-renew-dev.mile.im/back-office/landing/submit/early-bird", {
        name: name,
        companyName: company,
        email: email,
        meetingRoomNum: meetingRoomCount,
        memo: question,
        acceptTerm: agree,
      });

      if (res.data.result) {
        alert("신청이 완료되었습니다.");
        window.location.reload();
      } else {
        alert("신청에 실패했습니다. 다시 시도해주세요.");
      }
      setLoading(false);
    }
  };

  return (
    <RootContainer>
      <Container>
        <Header>
          <UI.Icon name="mileBlue" size={28} />
          <UI.Typography type="heading02" fontStyle="bold" color="var(--alias-color-primary-normal)">
            Mile
          </UI.Typography>
        </Header>
        <img
          width="100%"
          src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/event-images/Mile+Meeting+%E1%84%8B%E1%85%B5%E1%84%87%E1%85%A6%E1%86%AB%E1%84%90%E1%85%B3.png"
        />
        <img
          width="100%"
          src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/event-images/Mile+Meeting+%E1%84%8C%E1%85%A1%E1%84%8E%E1%85%A6+%E1%84%8B%E1%85%A8%E1%84%8B%E1%85%A3%E1%86%A8+%E1%84%92%E1%85%A9%E1%86%BC%E1%84%87%E1%85%A9%E1%84%86%E1%85%AE%E1%86%AF.png"
        />
        <ContentContainer>
          <div className="content">
            <div className="event-description">
              <UI.Typography type="body01 normal" color="var(--alias-color-label-normal)" fontStyle="regular">
                지금 Mile Meeting의 회의실 예약 시스템을 도입 신청하신다면,
              </UI.Typography>
              <BoxContainer>
                <div className="row-item">
                  <UI.Icon name="checkCircle" size={20} color="var(--alias-color-primary-normal)" />
                  <UI.Typography type="label01" color="var(--alias-color-label-normal)" fontStyle="regular">
                    2025년 1월까지 <strong style={{ fontWeight: 700 }}>회의실 개수 무제한 무료</strong>
                  </UI.Typography>
                </div>
                <div className="row-item">
                  <UI.Icon name="checkCircle" size={20} color="var(--alias-color-primary-normal)" />
                  <UI.Typography type="label01" color="var(--alias-color-label-normal)" fontStyle="regular">
                    회의실 2개까지 <strong style={{ fontWeight: 700 }}>기한 제한 없이 평생 무료</strong>
                  </UI.Typography>
                </div>
                <div className="row-item">
                  <UI.Icon name="checkCircle" size={20} color="var(--alias-color-primary-normal)" />
                  <UI.Typography type="label01" color="var(--alias-color-label-normal)" fontStyle="regular">
                    추가 비용 없이 <strong style={{ fontWeight: 700 }}>Google Workspace, Slack 연동</strong>
                  </UI.Typography>
                </div>
              </BoxContainer>
              <UI.Typography type="label01" fontStyle="regular" color="var(--alias-color-label-normal)">
                * 도입 신청해 주시면 평균 1일 이내로 구체적인 제품 안내 연락을 드립니다.
              </UI.Typography>
            </div>
            <div className="clients-description">
              <UI.Typography type="headline01" fontStyle="bold" color="var(--alias-color-label-normal)">
                Mile과 함께하는 고객사
              </UI.Typography>
              <UI.Typography type="body01 normal" fontStyle="regular" color="var(--alias-color-label-normal)">
                이미 많은 기업들이 Mile Meeting과 함께 회의실 운영 최적화를 경험하고 있습니다.
              </UI.Typography>
            </div>
            <div className="clients-list">
              <div className="column">
                <img
                  width={77}
                  src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/idus.png"
                />
                <img
                  width={108}
                  src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/bagelcode.png"
                />
              </div>
              <div className="column">
                <img
                  width={97}
                  src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/kmong.png"
                />
                <img
                  width={108}
                  src="https://mile-storage.s3.ap-northeast-2.amazonaws.com/landing/reference-company/mashup-ventures.svg"
                />
              </div>
            </div>
          </div>
          <FormContainer>
            <div className="form-list">
              <div className="input-form" id="name">
                <UI.Typography type="body02 normal" fontStyle="regular" color="var(--alias-color-label-normal)">
                  성함 <span style={{ color: "var(--alias-color-status-negative)" }}>*</span>
                </UI.Typography>
                <UI.Input placeholder="성함을 입력해 주세요." size="small" onChange={setName} />
              </div>
              <div className="input-form" id="company">
                <UI.Typography type="body02 normal" fontStyle="regular" color="var(--alias-color-label-normal)">
                  회사명 <span style={{ color: "var(--alias-color-status-negative)" }}>*</span>
                </UI.Typography>
                <UI.Input placeholder="회사명을 입력해 주세요." size="small" onChange={setCompany} />
              </div>
              <div className="input-form" id="email">
                <UI.Typography type="body02 normal" fontStyle="regular" color="var(--alias-color-label-normal)">
                  업무용 이메일 <span style={{ color: "var(--alias-color-status-negative)" }}>*</span>
                </UI.Typography>
                <UI.Input placeholder="ex) mile@mile.im" size="small" onChange={setEmail} />
              </div>
              <div className="input-form" id="meeting-room-count">
                <UI.Typography type="body02 normal" fontStyle="regular" color="var(--alias-color-label-normal)">
                  사내 회의실 수 <span style={{ color: "var(--alias-color-status-negative)" }}>*</span>
                </UI.Typography>
                <UI.Input
                  placeholder="숫자로 입력해 주세요."
                  type="number"
                  size="small"
                  onChange={(value) => setMeetingRoomCount(parseInt(value))}
                />
              </div>
              <div className="input-form" id="question">
                <UI.Typography type="body02 normal" fontStyle="regular" color="var(--alias-color-label-normal)">
                  문의사항 (선택)
                </UI.Typography>
                <UI.Input
                  onChange={setQuestion}
                  placeholder="궁금한 점을 남겨주세요."
                  height={160}
                  multiline={true}
                  size="small"
                />
              </div>
              <div className="checkbox-form">
                <UI.CheckBox onChange={() => setAgree(!agree)} selected={agree} />
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div onClick={() => window.open("https://policy.mile.im/privacy/2024.06.19.html")}>
                    <UI.Typography
                      type="label01"
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      color="var(--alias-color-label-neutral)"
                    >
                      개인정보 수집 및 이용
                    </UI.Typography>
                  </div>
                  <UI.Typography type="label01" fontStyle="regular" color="var(--alias-color-label-neutral)">
                    에 동의합니다.
                  </UI.Typography>
                </div>
              </div>
            </div>
            <UI.Button
              style={{
                width: "100%",
              }}
              prefixIcon="change"
              showPrefixIcon={loading}
              title={loading ? "" : "Mile Meeting 도입 신청하기"}
              size="large"
              disabled={!canOnHandleSubmit()}
              onClick={submit}
            />
          </FormContainer>
        </ContentContainer>
      </Container>
      <Footer />
    </RootContainer>
  );
};

export default Earlybird;
